import React, { Component } from "react";
import { Container } from "@material-ui/core";
import {
    Grid,
    Row,
    Col,
    FormGroup,
    ControlLabel,
    FormControl
} from "react-bootstrap";
import {TextField, Button} from "@material-ui/core"
import "assets/css/demo.css"
import logo from '../assets/img/cplogo.png'
import paths from 'paths.js'
import { Cookies } from 'react-cookie';


export default class Login extends Component {
    cookie = new Cookies()

    constructor(props){
        super(props)
        this.state = {
            username:'',
            password:''
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    
    handleChange(event) {
        const target = event.target;
        const value = target.value
        const name = target.name;
        this.setState({
            [name]: value
        });
    }
    handleSubmit(){
        fetch(paths.getBaseUrl() + '/api/Account/authenticate?password=' + btoa(this.state.password) + '&userId=' + btoa(this.state.username))
        // {
        //     headers: new Headers({
        //         'Cache-Control': 'no-cache',
        //         'cache': 'reload',
        //         'Pragma': 'no-cache',
        //         'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
        //     })
        // })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    throw new Error('Something went wrong ...');
                }
            })
            .then((result) => {
                console.log(result)
                // localStorage.setItem('handleDetails', JSON.stringify(object))
                this.cookie.set("access_token", result.data.token)
                // this.cookie.set("userInfo1", JSON.stringify(result))
                // this.cookie.set("userInfo2", JSON.stringify(result.data.name))
                this.cookie.set("user_Info", JSON.stringify(result.data))
                localStorage.setItem('userDetails', result.data.id)
                localStorage.setItem('user', result.data.role)
                // localStorage.setItem('userData', JSON.stringify(result))
                if(result.data.role === 'Steward'){
                    window.location.pathname = '/user/udashboard'
                }
                else{
                    window.location.pathname = '/admin/dashboard'
                }
                // setTimeout(() => {
                //     window.location.pathname = '/admin/dashboard'
                // }, 1000);

                
            })
            .catch((error) => {
                
                return error
            })
    }
    render(){
        return(
            <Row className="bg">
                    <Col md={4} />
                    <Col md={4} className="card" style={{padding:20}}>
                        <img src={logo} style={{width:'100%'}} />
                        <br />
                        <div className=" mt-20">
                            <form>
                                <div className="form-group">
                                    <TextField inputProps={{ autoComplete: 'off' }}
                                        autoFocus={true}
                                        style={{ width: '100%' }}
                                        type="text"
                                        name="username"
                                        label="Username"
                                        onChange={this.handleChange}
                                        placeholder="Please enter username"
                                        disabled={false} />
                                </div>
                                <div className="form-group">
                                    <TextField inputProps={{ autoComplete: 'off' }}
                                        style={{ width: '100%' }}
                                        disabled={false}
                                        type="password"
                                        name="password"
                                        label="Password"
                                        onChange={this.handleChange}
                                        placeholder="Please enter password" />
                                </div>
                                
                                <Row className="mt-10">
                                   

                                    <Col lg={6} md={6} sm={6} xs={6}>
                                    <Button style={{ background: '#a12664', color: '#fff' }} variant="contained" color="inherit" id={'loginBtn'} onClick={this.handleSubmit}>
                                            LOGIN
                                        
                                        </Button>
                                        
                                    </Col>

                                </Row>
                            </form>
                            
                        </div>

                    </Col>
            </Row>
        )
    }
}