/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl
} from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { UserCard } from "components/UserCard/UserCard.jsx";
import {Button} from "@material-ui/core";

import avatar from "assets/img/faces/face-3.jpg";
import {TextField} from "@material-ui/core";
import "assets/css/demo.css"
import Flatpickr from "react-flatpickr";
import 'flatpickr/dist/themes/light.css'
// import "flatpickr/dist/themes/";
import DataTable from 'react-data-table-component';
import {Rating} from '@material-ui/lab';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import paths from "paths";
import auth from "auth";
import Select from 'react-select';
import { style } from "variables/Variables.jsx";
import NotificationSystem from "react-notification-system";

const columns = [

  {
    name: 'Time',
    selector: 'time',
    sortable: true,
  },
  {
    name: 'Name of the Event',
    selector: 'eventName',
    sortable: true,
  },
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
  },
  {
    name: 'Candidate Name',
    selector: 'candidates',
    sortable: true,
  },
  {
    name: 'Duty Hours',
    selector: 'duty',
    sortable: true,
  }
];

const data = [
  { id: 1, eventName: 'Birthday Bash', date: '01-10-2020', time: '7PM to 9PM', candidates: 'Ramesh Shukla', duty: 2 },
  { id: 2, eventName: 'Wedding Bash', date: '02-10-2020', time: '7PM to 11PM', candidates: 'Ramesh Shukla', duty: 4 },
  { id: 3, eventName: 'Bachelors Party', date: '03-10-2020', time: '9PM to 11PM', candidates: 'Ramesh Shukla', duty: 2 },
  { id: 4, eventName: 'Investors Meet', date: '04-10-2020', time: '11AM to 2PM', candidates: 'Ramesh Shukla', duty: 3 },
  { id: 5, eventName: 'Birthday Bash', date: '04-10-2020', time: '7PM to 9PM', candidates: 'Ramesh Shukla', duty: 2 },
  { id: 6, eventName: 'Wedding Reception', date: '05-10-2020', time: '6PM to 11PM', candidates: 'Ramesh Shukla', duty: 5 },
  { id: 7, eventName: 'Christmas Eve', date: '25-10-2020', time: '7PM to 10PM', candidates: 'Ramesh Shukla', duty: 3 },

];


class UserProfile extends Component {
  constructor(props){
    super(props);
    this.state = {
      eventName:'',
      eventDate: new Date(),
      eventRate:0,
      eventStartTime: '',
      eventEndTime: '',
      reqCandidate:0,
      skills: '',
      eventList: [],
      selectedOption: null,
      rating:0,
      feedback: ''
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSelectChange = this.handleSelectChange.bind(this)
    this.onDateChange = this.onDateChange.bind(this)
    this.onFromTimeChange = this.onFromTimeChange.bind(this)
    this.onTillTimeChange = this.onTillTimeChange.bind(this)
    this.getEventList = this.getEventList.bind(this)
    this.getCandidateList = this.getCandidateList.bind(this)


  }

  componentDidMount() {
    this.getEventList()
  }

  handleChange(event){
    // alert()
    const target = event.target;
    const value = target.value
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  onDateChange(e, selectedDate) {
    if (selectedDate) {
      this.setState({
        eventDate: selectedDate
      })
    }
  }

  onFromTimeChange(e, selectedDate) {
    if (selectedDate) {
      this.setState({
        eventStartTime: selectedDate
      })
    }
  }

  onTillTimeChange(e, selectedDate) {
    if (selectedDate) {
      this.setState({
        eventEndTime: selectedDate
      })
    }
  }

  getEventList() {
    fetch(paths.getBaseUrl() + '/api/Event/GetAllNotDeletedEvents', {
      headers: new Headers({
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + (auth.getAccessToken()),
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
      })
    })
      .then(response => {
        if (response.status === 200) {
          return response.json()
        } else {
          throw new Error('Error');
        }
      })
      .then(result => {
        if (result !== []) {
          let candidateArray = Array();
          Object.keys(result.data).map((k) => {
            candidateArray.push({ value: result.data[k].id, label: result.data[k].eventName, fromTime: result.data[k].fromTime, toTime: result.data[k].toTime })
          })
          // candidateArray = result.data
          this.setState({
            eventList: candidateArray
          })
        } else {
          throw new Error('');
        }
      })
      .catch(error => console.log(''))
  }

  handleSelectChange = selectedOption => {
    this.setState({ selectedOption },
      () => this.getCandidateList());
    console.log(`Option selected:`, selectedOption);

  };

  getCandidateList() {
    fetch(paths.getBaseUrl() + '/api/EventUser/GetEventUserByEventID?eventId=' + this.state.selectedOption.value, {
      headers: new Headers({
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + (auth.getAccessToken()),
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
      })
    })
      .then(response => {
        if (response.status === 200) {
          return response.json()
        } else {
          throw new Error('Error');
        }
      })
      .then(result => {
        if (result !== []) {
          let candidateArray = Array();
          let i = 0
          Object.keys(result.data).map((k) => {
            if(result.data[k].isConfirmed && result.data[k].feedback === null){
              fetch(paths.getBaseUrl() + '/api/EventUser/GetAverageRatingsByUserID?userID=' + result.data[k].userID, {
                headers: new Headers({
                  'Accept': 'application/json',
                  'Authorization': 'Bearer ' + (auth.getAccessToken()),
                  'Content-Type': 'application/json',
                  'Cache-Control': 'no-cache',
                  'Pragma': 'no-cache',
                  'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
                })
              })
                .then(response => {
                  if (response.status === 200) {
                    return response.json()
                  } else {
                    throw new Error('Error');
                  }
                })
                .then(resultN => {
                  if (resultN !== null) {
                    // let candidateArray = Array();
                    // Object.keys(result.data).map((k) => {
                      // if (result.data[k].isConfirmed && result.data[k].feedback !== '') {

                        candidateArray.push({ rid: i, id: result.data[k].id, candidateName: result.data[k].userName, city: result.data[k].city, mobile: result.data[k].mobileNo, userId: result.data[k].userID, eventId: result.data[k].eventID, isConfirmed: result.data[k].isConfirmed, avgRating: resultN.data.averageRating })
                      // }
                    // })
                    // candidateArray = result.data
                    i = i + 1

                    this.setState({
                      canList: candidateArray
                    })
                  } else {
                    throw new Error('');
                  }
                })
                .catch(error => console.log(''))
              // candidateArray.push({ id: result.data[k].id, candidateName: result.data[k].userName, city: result.data[k].city, mobile: result.data[k].mobileNo, userId: result.data[k].userID, eventId: result.data[k].eventID, isConfirmed: result.data[k].isConfirmed, avgRating: 1 })
            }
            // this.setState({
            //   canList: candidateArray
            // })
          })
          // candidateArray = result.data
          
        } else {
          throw new Error('');
        }
      })
      .catch(error => console.log(''))
  }

  submitFeedback(eventId, rid){
    let rt = 'rating' + rid
    fetch(paths.getBaseUrl() + '/api/EventUser/UpdateEventUserFeedBackAndRating?Id=' + eventId + '&FeedBack=' + this.state[rid] + '&Ratings=' + this.state[rt], {
      method: 'PUT',
      // body: JSON.stringify(formBody),

      headers: new Headers({
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + (auth.getAccessToken()),
        'Content-Type': 'application/json'
      })
    })
      .then(response => {
        if (response.status === 200) {
          return response.json()
        } else {
          throw new Error();
        }
      })
      .then(result => {
        if (result.statusCode === 200) {
          // window.location.reload()
          this.setState({ _notificationSystem: this.refs.notificationSystem });
          var _notificationSystem = this.refs.notificationSystem;
          // var color = Math.floor(Math.random() * 4 + 1);
          var level = 'success';

          _notificationSystem.addNotification({
            title: <span data-notify="icon" className="pe-7s-gift" />,
            message: (
              <div>
                Feedback Submitted successfully.
              </div>
            ),
            level: level,
            position: "tr",
            autoDismiss: 10
          });
          for (let index = 0; index < 50; index++) {
            let r = "rating" + index.toString()
            this.setState({
              [index]: undefined,
              [r]: undefined
            })
            
          }
          this.setState({
            canList: [],
            
          },
            () => this.getCandidateList())

        } else {
          this.setState({ _notificationSystem: this.refs.notificationSystem });
          var _notificationSystem = this.refs.notificationSystem;
          // var color = Math.floor(Math.random() * 4 + 1);
          var level = 'error';

          _notificationSystem.addNotification({
            title: <span data-notify="icon" className="pe-7s-gift" />,
            message: (
              <div>
                {result.message}
              </div>
            ),
            level: level,
            position: "tr",
            autoDismiss: 10
          });
          // throw new Error('');
        }

      })
      .catch(error => {
        this.setState({ _notificationSystem: this.refs.notificationSystem });
        var _notificationSystem = this.refs.notificationSystem;
        // var color = Math.floor(Math.random() * 4 + 1);
        var level = 'error';

        _notificationSystem.addNotification({
          title: <span data-notify="icon" className="pe-7s-gift" />,
          message: (
            <div>
              Something went wrong.
            </div>
          ),
          level: level,
          position: "tr",
          autoDismiss: 10
        });
        console.log(error)
      })
  }

  render() {
    return (
      <div className="content">
        <NotificationSystem ref="notificationSystem" style={style} />

        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
              // title="History of Candidate"
              content={
                <>
                  {/* <Button style={{ fontWeight: "bold", background: 'orange', color: '#fff' }}>Rating</Button>
                  <Rating name="read-only" value={3} readOnly /> */}
                    <Row>
                      <Col md={6}>
                        <small>Select Event:</small>
                        <Select
                          value={this.state.selectedOption}
                          onChange={this.handleSelectChange}
                          options={this.state.eventList}
                        />
                      </Col>
                      {this.state.selectedOption !== null ?


                        <Col md={6}>
                          <br />
                          Event Name : {this.state.selectedOption['label']}
                          <br />
                          Date : {this.state.selectedOption['fromTime'].split('T')[0]} | {this.state.selectedOption['fromTime'].split('T')[1].split('Z')[0].slice(0, 5) + ' to ' + this.state.selectedOption['toTime'].split('T')[1].split('Z')[0].slice(0, 5)}
                        </Col>

                        : ''}
                    </Row>
                <DataTable
                      columns={[
                        {
                          name: 'Candidate Name',
                          selector: 'candidateName',
                          sortable: true,
                        },
                        {
                          name: 'Mobile No.',
                          selector: 'mobile',
                          sortable: true,
                        },
                        {
                          name: 'City',
                          selector: 'city',
                          sortable: true,
                        },
                        {
                          name: 'Overall Rating',
                          // selector: 'city',
                          sortable: true,
                          cell: row => <div><Rating name="read-only" value={row.avgRating} readOnly /></div>
                        },
                        {
                          name: 'Feedback',
                          sortable: true,
                          cell: row => <TextField name={row.rid} value={this.state[row.rid]} onBlur={this.handleChange}></TextField>,
                        },
                        {
                          name: 'Rating',
                          sortable: true,
                          cell: row => <Rating name={"rating" + row.rid} onChange={this.handleChange} value={this.state["rating"+row.rid]} precision={0.5}  />,
                        },
                        {
                          name: '#',
                          sortable: true,
                          cell: row => <Button onClick={this.submitFeedback.bind(this, row.id, row.rid)} style={{ fontWeight: "bold", background: '#a12664', color: '#fff' }}>Submit</Button>,
                        }
                      ]}
                data={this.state.canList}
                striped
                 />
                </>
              }
               />
              
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default UserProfile;
