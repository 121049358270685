/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl
} from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { UserCard } from "components/UserCard/UserCard.jsx";
import {Button} from "@material-ui/core";

import avatar from "assets/img/faces/face-3.jpg";
import {TextField} from "@material-ui/core";
import "assets/css/demo.css"
import Flatpickr from "react-flatpickr";
import 'flatpickr/dist/themes/light.css'
// import "flatpickr/dist/themes/";
import paths from "paths";
import auth from "auth";
import { style } from "variables/Variables.jsx";
import NotificationSystem from "react-notification-system";
import DataTable from 'react-data-table-component';


const columns = [
  {
    name: 'Name of the Event',
    selector: 'eventName',
    sortable: true,
  },
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
  },
  {
    name: 'Time',
    selector: 'time',
    sortable: true,
  },
  {
    name: 'No. of Candidates',
    selector: 'candidates',
    sortable: true,
  }
];

const data = [
  { id: 1, eventName: 'Birthday Bash', date: '01-12-2020', time: '7PM to 9PM', candidates: 50 },
  { id: 2, eventName: 'Wedding Bash', date: '02-12-2020', time: '7PM to 11PM', candidates: 50 },
  { id: 3, eventName: 'Bachelors Party', date: '03-12-2020', time: '9PM to 11PM', candidates: 50 },
  { id: 4, eventName: 'Investors Meet', date: '04-12-2020', time: '11AM to 2PM', candidates: 50 },
  { id: 5, eventName: 'Birthday Bash', date: '04-12-2020', time: '7PM to 9PM', candidates: 50 },
  { id: 6, eventName: 'Wedding Reception', date: '05-12-2020', time: '6PM to 11PM', candidates: 50 },
  { id: 7, eventName: 'Christmas Eve', date: '25-12-2020', time: '7PM to 10PM', candidates: 50 },

];

class UserProfile extends Component {
  constructor(props){
    super(props);
    this.state = {
      eventName:'',
      eventDate: new Date(),
      eventRate:0,
      eventStartTime: '12:00',
      eventEndTime: '12:00',
      reqCandidate:0,
      skills:'',
      disabled: false
    }

    this.handleChange = this.handleChange.bind(this)
    this.createEvent = this.createEvent.bind(this)
    this.onDateChange = this.onDateChange.bind(this)
    this.onFromTimeChange = this.onFromTimeChange.bind(this)
    this.onTillTimeChange = this.onTillTimeChange.bind(this)


  }

  handleChange(event){
    const target = event.target;
    const value = target.value
    const name = target.name;
    this.setState({
      [name]: value.toUpperCase()
    });
  }

  onDateChange(e, selectedDate) {
    // alert(selectedDate)
    if (selectedDate) {
      this.setState({
        eventDate: new Date(selectedDate)
      })
    }
  }

  onFromTimeChange(e, selectedDate) {
    if (selectedDate) {
      this.setState({
        eventStartTime: selectedDate
      })
    }
  }

  onTillTimeChange(e, selectedDate) {
    if (selectedDate) {
      this.setState({
        eventEndTime: selectedDate
      })
    }
  }

  createEvent(){
    this.setState({
      disabled: true
    })
    var eDate1 = new Date(this.state.eventDate).toISOString().split('T')[0]
    // .split('-')
    var eDate = eDate1.split('-')
    console.log(typeof eDate)
    var sDate = new Date(eDate[0], eDate[1] - 1, eDate[2], this.state.eventStartTime.split(':')[0], this.state.eventStartTime.split(':')[1], 0)
    var tDate = new Date(eDate[0], eDate[1] - 1, eDate[2], this.state.eventEndTime.split(':')[0], this.state.eventEndTime.split(':')[1], 0)
    console.log(sDate, tDate)
    var eventData = {
      "EventName": this.state.eventName,
      "PerHrRate": this.state.eventRate,
      "FromTime": sDate.toISOString(),
      "ToTime": tDate.toISOString(),
      "TotalCandidatesApply": this.state.reqCandidate,
      "Skills": "",
      "EventType": 0
    }
    console.log(eventData)
    fetch(paths.getBaseUrl() + '/api/Event/Post', {
      method: 'POST',
      body: JSON.stringify(eventData),

      headers: new Headers({
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + (auth.getAccessToken()),
        'Content-Type': 'application/json'
      })
    })
      .then(response => {
        if (response.status === 200) {
          return response.json()
        } else {
          throw new Error('');
        }
      })
      .then(result => {
        if (result.statusCode === 200) {
          // window.location.reload()
          this.setState({ _notificationSystem: this.refs.notificationSystem });
          var _notificationSystem = this.refs.notificationSystem;
          // var color = Math.floor(Math.random() * 4 + 1);
          var level = 'success';

          _notificationSystem.addNotification({
            title: <span data-notify="icon" className="pe-7s-gift" />,
            message: (
              <div>
                Event added successfully.
              </div>
            ),
            level: level,
            position: "tr",
            autoDismiss: 5
          });

          this.setState({
            disabled: false,
            eventName: '',
            eventDate: new Date(),
            eventRate: 0,
            eventStartTime: '12:00',
            eventEndTime: '12:00',
            reqCandidate: 0,
            skills: ''
          })

        } else {
          this.setState({ 
            _notificationSystem: this.refs.notificationSystem,
            disabled: false
          });
          var _notificationSystem = this.refs.notificationSystem;
          // var color = Math.floor(Math.random() * 4 + 1);
          var level = 'error';

          _notificationSystem.addNotification({
            title: <span data-notify="icon" className="pe-7s-gift" />,
            message: (
              <div>
                {result.message}
              </div>
            ),
            level: level,
            position: "tr",
            autoDismiss: 5
          });
          // throw new Error('');
        }

      })
      .catch(error => {
        this.setState({ 
          _notificationSystem: this.refs.notificationSystem,
          disabled: false
        });
        var _notificationSystem = this.refs.notificationSystem;
        // var color = Math.floor(Math.random() * 4 + 1);
        var level = 'error';

        _notificationSystem.addNotification({
          title: <span data-notify="icon" className="pe-7s-gift" />,
          message: (
            <div>
              Something went wrong.
            </div>
          ),
          level: level,
          position: "tr",
          autoDismiss: 10
        });
        console.log(error)
      })
  }

  render() {
    return (
      <div className="content">
        <NotificationSystem ref="notificationSystem" style={style} />
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                // title="Create Event"
                content={
                  <>
                  <Row>
                    <Col md={4}>
                      <TextField
                        label="Event Name"
                        onChange={this.handleChange}
                        value={this.state.eventName}
                        name='eventName' />
                    </Col>
                    <Col md={4}>
                        <TextField
                          label="Rate"
                          onChange={this.handleChange}
                          value={this.state.eventRate}
                          name='eventRate' />
                    </Col>
                    <Col md={4}>
                        <TextField
                          label="No. of Candidate"
                          onChange={this.handleChange}
                          value={this.state.reqCandidate}
                          name='reqCandidate' />
                    </Col>
                    
                    
                  </Row>
                  <br />
                  <Row>
                    <Col md={4}>
                      <small>Event Date</small>
                      <Flatpickr
                        value={this.state.eventDate}
                        options={{
                          // minDate: this.state.minDate,
                          // dateFormat: 'd-m-Y',
                          // altInput: true,
                          monthSelectorType: 'static',
                          // altFormat: "d-m-Y",
                          // altInputClass: 'form-control whiteBackground',
                          wrap: true,
                          minDate: new Date().toISOString().split('T')[0]
                        }}
                        onChange={this.onDateChange}>
                        <div className='input-group'>
                          <input className="form-control whiteBackground" type="password" placeholder="Event Date" data-input={true} />
                        </div>
                      </Flatpickr>
                    </Col>
                      <Col md={4}>
                        <small>Time From</small>
                        <Flatpickr
                          value={this.state.eventStartTime}
                          // data-enable-time
                          options={{
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: "H:i",
                            altInputClass: 'form-control whiteBackground',
                            wrap: true,
                            // minDate: new Date().toISOString().split('T')[0]
                          }}
                          onChange={this.onFromTimeChange}>
                          <div className='input-group'>
                            <input className="form-control whiteBackground" type="password" placeholder="Event Date" data-input={true} />
                          </div>
                        </Flatpickr>
                      </Col>
                      <Col md={4}>
                        <small>Time Till</small>
                        <Flatpickr
                          value={this.state.eventEndTime}
                          // data-enable-time
                          options={{
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: "H:i",
                            altInputClass: 'form-control whiteBackground',
                            wrap: true,
                            // minDate: new Date().toISOString().split('T')[0]
                          }}
                          onChange={this.onTillTimeChange}>
                          <div className='input-group'>
                            <input className="form-control whiteBackground" type="password" placeholder="Event Date" data-input={true} />
                          </div>
                        </Flatpickr>
                      </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={4} style={{float:"left"}}>
                        <Button disabled={this.state.disabled} onClick={this.createEvent} style={{ fontWeight: "bold", background: '#a12664', color: '#fff', margin: 2 }} variant="contained" color="inherit">Submit</Button>
                    </Col>
                  </Row>
                  </>
                }
              />
            </Col>
            
          </Row>
          {/* <Row>
            <Col md={12}>
              <Card
              // title="All Events"
              content={
                <DataTable
                columns = {columns}
                data={data} 
                striped
                title='All Events' />
              } />
            </Col>
          </Row> */}
        </Grid>
      </div>
    );
  }
}

export default UserProfile;
