/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl
} from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { UserCard } from "components/UserCard/UserCard.jsx";
import {Button} from "@material-ui/core";

import avatar from "assets/img/faces/face-3.jpg";
import {TextField} from "@material-ui/core";
import "assets/css/demo.css"
import Flatpickr from "react-flatpickr";
import 'flatpickr/dist/themes/light.css'
// import "flatpickr/dist/themes/";
import DataTable from 'react-data-table-component';


const columns = [
  {
    name: 'Name of the Event',
    selector: 'eventName',
    sortable: true,
  },
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
  },
  {
    name: 'Time',
    selector: 'time',
    sortable: true,
    },
    {
        name: 'No. of Candidates',
        selector: 'candidates',
        sortable: true,
    }
];

const data = [
    { id: 1, eventName: 'Birthday Bash', date: '01-12-2020', time: '7PM to 9PM', candidates: 50 },
    { id: 2, eventName: 'Wedding Bash', date: '02-12-2020', time: '7PM to 11PM', candidates: 50 },
    { id: 3, eventName: 'Bachelors Party', date: '03-12-2020', time: '9PM to 11PM', candidates: 50 },
    { id: 4, eventName: 'Investors Meet', date: '04-12-2020', time: '11AM to 2PM', candidates: 50 },
    { id: 5, eventName: 'Birthday Bash', date: '04-12-2020', time: '7PM to 9PM', candidates: 50 },
    { id: 6, eventName: 'Wedding Reception', date: '05-12-2020', time: '6PM to 11PM', candidates: 50 },
    { id: 7, eventName: 'Christmas Eve', date: '25-12-2020', time: '7PM to 10PM', candidates: 50 },

];


class UserProfile extends Component {
  constructor(props){
    super(props);
    this.state = {
      eventName:'',
      eventDate: new Date(),
      eventRate:0,
      eventStartTime: '',
      eventEndTime: '',
      reqCandidate:0,
      skills:''

    }

    this.handleChange = this.handleChange.bind(this)
    this.onDateChange = this.onDateChange.bind(this)
    this.onFromTimeChange = this.onFromTimeChange.bind(this)
    this.onTillTimeChange = this.onTillTimeChange.bind(this)


  }

  handleChange(event){
    const target = event.target;
    const value = target.value
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  onDateChange(e, selectedDate) {
    if (selectedDate) {
      this.setState({
        eventDate: selectedDate
      })
    }
  }

  onFromTimeChange(e, selectedDate) {
    if (selectedDate) {
      this.setState({
        eventStartTime: selectedDate
      })
    }
  }

  onTillTimeChange(e, selectedDate) {
    if (selectedDate) {
      this.setState({
        eventEndTime: selectedDate
      })
    }
  }

  render() {
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
              title="All Events"
              content={
                <DataTable
                columns = {columns}
                data={data}
                striped
                title="Event List" />
              } />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default UserProfile;
