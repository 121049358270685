/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.jsx";
import UserProfile from "views/UserProfile.jsx";
import Report from "views/Report";
import History from "views/History";
import MessagePortal from "views/MessagePortal";
import TodaysEvent from "views/TodaysEvents";
import AllEvents from "views/AllEvents";
import UpcomingEvents from "views/UpcomingEvents";
import RegisterCandidate from "views/RegisterCandidate";
import AppliedCandidates from "views/AppliedCandidates";
// import Notifications from "views/Notifications.jsx";
// import Upgrade from "views/Upgrade.jsx";

const dashboardRoutes = [

  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "pe-7s-bell",
    component: Dashboard,
    layout: "/admin"
  },
  // {
  //   path: "/allEvents",
  //   name: "All Events",
  //   icon: "pe-7s-map-marker",
  //   component: AllEvents,
  //   layout: "/admin"
  // },
  // {
  //   path: "/todaysEvents",
  //   name: "Today's Event",
  //   icon: "pe-7s-graph",
  //   component: TodaysEvent,
  //   layout: "/admin"
  // },
  {
    path: "/createEvent",
    name: "Create Event",
    icon: "pe-7s-user",
    component: UserProfile,
    layout: "/admin"
  },
  {
    path: "/upcomingEvent",
    name: "Upcoming Event",
    icon: "pe-7s-note2",
    component: UpcomingEvents,
    layout: "/admin"
  },
  // {
  //   path: "/report",
  //   name: "Report",
  //   icon: "pe-7s-news-paper",
  //   component: Report,
  //   layout: "/admin"
  // },
  {
    path: "/registerCandidate",
    name: "Register Candidate",
    icon: "pe-7s-science",
    component: RegisterCandidate,
    layout: "/admin"
  },

  {
    path: "/appliedCandidates",
    name: "Applied Candidates",
    icon: "pe-7s-science",
    component: AppliedCandidates,
    layout: "/admin"
  },
  // {
  //   path: "/appliedEvents",
  //   name: "Applied Event",
  //   icon: "pe-7s-note2",
  //   component: AppliedEvents,
  //   layout: "/user"
  // }
  {
    path: "/history",
    name: "History & Feedback",
    icon: "pe-7s-science",
    component: History,
    layout: "/admin"
  },
  {
    path: "/messagePortal",
    name: "Message Portal",
    icon: "pe-7s-science",
    component: MessagePortal,
    layout: "/admin"
  }
];

export default dashboardRoutes;
