/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl
} from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { UserCard } from "components/UserCard/UserCard.jsx";
import { Button } from "@material-ui/core";

import avatar from "assets/img/faces/face-3.jpg";
import { TextField } from "@material-ui/core";
import "assets/css/demo.css"
import Flatpickr from "react-flatpickr";
import 'flatpickr/dist/themes/light.css'
// import "flatpickr/dist/themes/";
import DataTable from 'react-data-table-component';
import paths from "paths";
import auth from "auth";
import { style } from "variables/Variables.jsx";
import NotificationSystem from "react-notification-system";


const columns = [
  {
    name: 'Candidate Name',
    selector: 'candidateName',
    sortable: true,
  },
  {
    name: 'Email',
    selector: 'emailId',
    sortable: true,
  },
  {
    name: 'Mobile No.',
    selector: 'mobile',
    sortable: true,
  },
  {
    name: 'City',
    selector: 'city',
    sortable: true,
  }
];

const data = [
  { id: 1, eventName: 'Birthday Bash', date: '01-12-2020', time: '7PM to 9PM', candidates: 50 },
  { id: 2, eventName: 'Wedding Bash', date: '02-12-2020', time: '7PM to 11PM', candidates: 50 },
  { id: 3, eventName: 'Bachelors Party', date: '03-12-2020', time: '9PM to 11PM', candidates: 50 },
  { id: 4, eventName: 'Investors Meet', date: '04-12-2020', time: '11AM to 2PM', candidates: 50 },
  { id: 5, eventName: 'Birthday Bash', date: '04-12-2020', time: '7PM to 9PM', candidates: 50 },
  { id: 6, eventName: 'Wedding Reception', date: '05-12-2020', time: '6PM to 11PM', candidates: 50 },
  { id: 7, eventName: 'Christmas Eve', date: '25-12-2020', time: '7PM to 10PM', candidates: 50 },

];

class UserProfile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      canList: [],
      candidateName: '',
      username: '',
      userId: '',
      contactNo: '',
      mobileNo: '',
      emailId: '',
      password: '',
      address: '',
      city: '',
      state: '',
      pincode: '',
      disabled: false
    }

    this.handleChange = this.handleChange.bind(this)
    this.addUser = this.addUser.bind(this)
    this.getCandidateList = this.getCandidateList.bind(this)

  }

  handleChange(event) {
    const target = event.target;
    const value = target.value
    const name = target.name;
    this.setState({
      [name]: value.toUpperCase()
    });
  }

  addUser() {
    this.setState({
      disabled: true
    })
    let userData = {

      "name": this.state.candidateName,
      "userId": this.state.username,
      "userName": this.state.username,
      "password": btoa(this.state.password),
      "contactDetails": {
        "contactNo": this.state.contactNo === '' ? '1111111111' : this.state.contactNo,
        "mobileNo": this.state.mobileNo,
        "emailId": this.state.emailId === '' ? 'null@null.com' : this.state.emailId,
        "address": this.state.address,
        "city": this.state.city,
        "state": this.state.state,
        "country": "India",
        "pinCode": this.state.pincode === '' ? '111111' : this.state.pincode
      },
      "skills": "",
      "role": "Steward"
    }

    fetch(paths.getBaseUrl() + '/api/Account/register', {
      method: 'POST',
      body: JSON.stringify(userData),

      headers: new Headers({
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + (auth.getAccessToken()),
        'Content-Type': 'application/json'
      })
    })
      .then(response => {
        if (response.status === 200) {
          return response.json()
        } else {
          throw new Error('');
        }
      })
      .then(result => {
        if (!result.statusCode) {
          // window.location.reload()
          this.setState({ disabled: false, _notificationSystem: this.refs.notificationSystem });
          var _notificationSystem = this.refs.notificationSystem;
          // var color = Math.floor(Math.random() * 4 + 1);
          var level = 'success';
          
          _notificationSystem.addNotification({
            title: <span data-notify="icon" className="pe-7s-gift" />,
            message: (
              <div>
                Candidate added successfully.
              </div>
            ),
            level: level,
            position: "tr",
            autoDismiss: 5
          });

          this.setState({
            canList: [],
            candidateName: '',
            username: '',
            userId: '',
            contactNo: '',
            mobileNo: '',
            emailId: '',
            password: '',
            address: '',
            city: '',
            state: '',
            pincode: ''
          }, 
          () => this.getCandidateList())

        } else {
          this.setState({ disabled: false, _notificationSystem: this.refs.notificationSystem });
          var _notificationSystem = this.refs.notificationSystem;
          // var color = Math.floor(Math.random() * 4 + 1);
          var level = 'error';

          _notificationSystem.addNotification({
            title: <span data-notify="icon" className="pe-7s-gift" />,
            message: (
              <div>
                {result.message}
              </div>
            ),
            level: level,
            position: "tr",
            autoDismiss: 5
          });
          // throw new Error('');
        }

      })
      .catch(error => {
        this.setState({ disabled: false, _notificationSystem: this.refs.notificationSystem });
        var _notificationSystem = this.refs.notificationSystem;
        // var color = Math.floor(Math.random() * 4 + 1);
        var level = 'error';

        _notificationSystem.addNotification({
          title: <span data-notify="icon" className="pe-7s-gift" />,
          message: (
            <div>
              Something went wrong.
            </div>
          ),
          level: level,
          position: "tr",
          autoDismiss: 5
        });
        console.log(error)
      })
  }

  componentDidMount() {
    this.getCandidateList()
  }

  getCandidateList() {
    fetch(paths.getBaseUrl() + '/api/Account/GetAll', {
      headers: new Headers({
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + (auth.getAccessToken()),
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
      })
    })
      .then(response => {
        if (response.status === 200) {
          return response.json()
        } else {
          throw new Error('Error');
        }
      })
      .then(result => {
        if (result !== []) {
          let candidateArray = Array();
          Object.keys(result).map((k) => {
            // console.log(this.state.selectedDeviceId)
            if(result[k].role === 'Steward'){
              candidateArray.push({ id: result[k].id, candidateName: result[k].name, emailId: result[k].contactDetails.emailId, mobile: result[k].contactDetails.mobileNo, city: result[k].contactDetails.city })

            }
          })
          this.setState({
            canList: candidateArray
          })
        } else {
          throw new Error('');
        }
      })
      .catch(error => console.log(''))
  }
  render() {
    return (
      <div className="content">
        <NotificationSystem ref="notificationSystem" style={style} />
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title=""
                content={
                  <>
                    <Row>
                      <Col md={4}>
                        <TextField
                          label="Candidate's Name"
                          onChange={this.handleChange}
                          value={this.state.candidateName}
                          name='candidateName' />
                      </Col>
                      <Col md={4}>
                        <TextField
                          label="Username"
                          onChange={this.handleChange}
                          value={this.state.username}
                          name='username' />
                      </Col>
                      <Col md={4}>
                        <TextField
                          label="Password"
                          onChange={this.handleChange}
                          value={this.state.password}
                          name='password' />
                      </Col>


                    </Row>
                    <br />
                    <Row>
                      <Col md={4}>
                        <TextField
                          label="Mobile No."
                          onChange={this.handleChange}
                          value={this.state.mobileNo}
                          name='mobileNo' />
                      </Col>
                      <Col md={4}>
                        <TextField
                          label="Email Id"
                          onChange={this.handleChange}
                          value={this.state.emailId}
                          name='emailId' />
                      </Col>

                    </Row>
                    <br />
                    <Row>
                      <Col md={3}>
                        <TextField
                          label="Address"
                          onChange={this.handleChange}
                          value={this.state.address}
                          name='address' />
                      </Col>
                      <Col md={3}>
                        <TextField
                          label="City"
                          onChange={this.handleChange}
                          value={this.state.city}
                          name='city' />
                      </Col>
                      <Col md={3}>
                        <TextField
                          label="State"
                          onChange={this.handleChange}
                          value={this.state.state}
                          name='state' />
                      </Col>
                      <Col md={3}>
                        <TextField
                          label="Pincode"
                          onChange={this.handleChange}
                          value={this.state.pincode}
                          name='pincode' />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col md={4} style={{ float: "left" }}>
                        <Button disabled={this.state.disabled} style={{ fontWeight: "bold", background: '#a12664', color: '#fff', margin: 2 }} onClick={this.addUser} variant="contained">Submit</Button>
                      </Col>
                    </Row>
                  </>
                }
              />
            </Col>

          </Row>
          <Row>
            <Col md={12}>
              <Card
                // title="All Events"
                content={
                  <DataTable
                    columns={columns}
                    data={this.state.canList}
                    striped
                    title='Candidate List'
                    pagination={true} />
                } />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default UserProfile;

