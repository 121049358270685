class URLBuilder {

    getBaseUrl() {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            // dev code
            this.protocol = 'http'
            // this.domain = '104.211.157.8'
            this.domain = '40.71.84.110'
            // this.domain = 'localhost'
            // this.domain = 'localhost'
            // this.domain = 'localhost'
            this.port = '5010'
        } else {
            // production code
            this.protocol = 'http'
            // this.domain = '192.168.1.103'
            // this.domain = '104.211.157.8'
            this.port = '5010'
            this.domain = document.location.hostname
        }
        // http://localhost:3000/summary
        const url = this.protocol + '://' + this.domain + ':' + this.port
        // const url = this.protocol + '://' + this.domain

        return url
    }
}

export default new URLBuilder();
