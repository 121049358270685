import { Cookies } from 'react-cookie';
// import route from './route';

class Auth {
    cookie = new Cookies()
    // signIn(username, password, done) {
    //     this.cookie.remove("userInfo2");
    //     this.cookie.remove("user_Info");
    //     this.cookie.remove("_par");
    //     this.cookie.remove("_menu");
    //     const encodedPassword = btoa(password);
    //     const encodedUsername = btoa(username);
    //     fetch(route.getBaseUrl() + '/api/Account/authenticate?password=' + encodedPassword + '&userId=' + encodedUsername, {
    //         headers: new Headers({
    //             'Cache-Control': 'no-cache',
    //             'cache': 'reload',
    //             'Pragma': 'no-cache',
    //             'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
    //         })
    //     })
    //         .then(response => {
    //             if (response.status === 200) {
    //                 return response.json();
    //             } else {
    //                 throw new Error('Something went wrong ...');
    //             }
    //         })
    //         .then((result) => {
    //             let object = {};
    //             let handles = result.data.handleList;
    //             for (let i = 0; i < handles.length; i++) {
    //                 let details = handles[i]
    //                 object[details.handleName] = {
    //                     details
    //                 }
    //             }
    //             localStorage.setItem('handleDetails', JSON.stringify(object))
    //             this.cookie.set("access_token", result.data.token)
    //             // this.cookie.set("userInfo1", JSON.stringify(result))
    //             this.cookie.set("userInfo2", JSON.stringify(result.data.name))
    //             this.cookie.set("user_Info", JSON.stringify(result.data.roleDetails))
    //             localStorage.setItem('userDetails', result.data.contactDetails)
    //             localStorage.setItem('user', result.data.name)
    //             localStorage.setItem('userData', JSON.stringify(result))
    //             done();
    //         })
    //         .catch((error) => {
    //             done();
    //             return error
    //         })
    // }

    signOut() {
        window.location.href = "/login";
        this.cookie.remove("access_token");
        this.cookie.remove("_menu");
        this.cookie.remove("userInfo2");
        this.cookie.remove("user_Info");
        this.cookie.remove("_par");
        localStorage.removeItem("userData");
        localStorage.removeItem("userDetails");
        localStorage.removeItem("user");
        localStorage.removeItem("per");
        localStorage.clear()
        // setTimeout(() => {
        //     window.location.href = "/login";
        // }, 500);
    }

    getAccessToken() {
        return this.cookie.get("access_token");
    }

    hasAccessToken() {
        return this.getAccessToken() != null;
    }

    authHeader() {
        // return authorization header with jwt token
        let user = JSON.parse(this.cookie.get('user'));

        if (user && user.token) {
            return { 'Authorization': 'Bearer ' + user.token };
        } else {
            return {};
        }
    }

    getRole() {
        let role = JSON.parse(localStorage.getItem('userData'))
        role = role.role
        return role
    }

    getTemporaryToken() {
        return this.cookie.get("temp_token");
    }
}

export default new Auth();