/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import {
    Grid,
    Row,
    Col,
    FormGroup,
    ControlLabel,
    FormControl
} from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { UserCard } from "components/UserCard/UserCard.jsx";
import { Button} from "@material-ui/core";

import avatar from "assets/img/faces/face-3.jpg";
import { TextField } from "@material-ui/core";
import "assets/css/demo.css"
import Flatpickr from "react-flatpickr";
import 'flatpickr/dist/themes/light.css'
// import "flatpickr/dist/themes/";
import DataTable from 'react-data-table-component';
import paths from "paths";
import auth from "auth";
import Select from 'react-select';
import { style } from "variables/Variables.jsx";
import NotificationSystem from "react-notification-system";

const columns = [
    {
        name: 'Candidate Name',
        selector: 'candidateName',
        sortable: true,
    },
    {
        name: 'Mobile No.',
        selector: 'date',
        sortable: true,
    },
    {
        name: 'City',
        selector: 'city',
        sortable: true,
    },
    {
        name: '#',
        sortable: true,
        cell: row => <Button onClick={this.applyEvent.bind(this, row.id, row.eventName)} style={{ fontWeight: "bold", background: 'green', color: '#fff' }}>Apply</Button>,
    }
];

const data = [
    { id: 1, eventName: 'Birthday Bash', date: '01-12-2020', time: '7PM to 9PM', candidates: 50 },
    { id: 2, eventName: 'Wedding Bash', date: '02-12-2020', time: '7PM to 11PM', candidates: 50 },
    { id: 3, eventName: 'Bachelors Party', date: '03-12-2020', time: '9PM to 11PM', candidates: 50 },
    { id: 4, eventName: 'Investors Meet', date: '04-12-2020', time: '11AM to 2PM', candidates: 50 },
    { id: 5, eventName: 'Birthday Bash', date: '04-12-2020', time: '7PM to 9PM', candidates: 50 },
    { id: 6, eventName: 'Wedding Reception', date: '05-12-2020', time: '6PM to 11PM', candidates: 50 },
    { id: 7, eventName: 'Christmas Eve', date: '25-12-2020', time: '7PM to 10PM', candidates: 50 },

];


class UserProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventName: '',
            eventDate: new Date(),
            eventRate: 0,
            eventStartTime: '',
            eventEndTime: '',
            reqCandidate: 0,
            skills: '',
            eventList: [],
            selectedOption: null
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSelectChange = this.handleSelectChange.bind(this)
        this.onDateChange = this.onDateChange.bind(this)
        this.onFromTimeChange = this.onFromTimeChange.bind(this)
        this.onTillTimeChange = this.onTillTimeChange.bind(this)
        this.getEventList = this.getEventList.bind(this)
        this.getCandidateList = this.getCandidateList.bind(this)
        this.confirmCandidate = this.confirmCandidate.bind(this)


    }


    handleSelectChange = selectedOption => {
        this.setState({ selectedOption },
            () => this.getCandidateList());
        console.log(`Option selected:`, selectedOption);
        
    };

    handleChange(event) {
        const target = event.target;
        const value = target.value
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    onDateChange(e, selectedDate) {
        if (selectedDate) {
            this.setState({
                eventDate: selectedDate
            })
        }
    }

    onFromTimeChange(e, selectedDate) {
        if (selectedDate) {
            this.setState({
                eventStartTime: selectedDate
            })
        }
    }

    onTillTimeChange(e, selectedDate) {
        if (selectedDate) {
            this.setState({
                eventEndTime: selectedDate
            })
        }
    }

    componentDidMount() {
        this.getEventList()
    }

    getEventList() {
        fetch(paths.getBaseUrl() + '/api/Event/GetAllNotDeletedEvents', {
            headers: new Headers({
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + (auth.getAccessToken()),
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
            })
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json()
                } else {
                    throw new Error('Error');
                }
            })
            .then(result => {
                if (result !== []) {
                    let candidateArray = Array();
                    Object.keys(result.data).map((k) => {
                        candidateArray.push({ value: result.data[k].id, label: result.data[k].eventName, fromTime: result.data[k].fromTime, toTime: result.data[k].toTime })
                    })
                    // candidateArray = result.data
                    this.setState({
                        eventList: candidateArray
                    })
                } else {
                    throw new Error('');
                }
            })
            .catch(error => console.log(''))
    }

    getCandidateList() {
        fetch(paths.getBaseUrl() + '/api/EventUser/GetEventUserByEventID?eventId=' + this.state.selectedOption.value, {
            headers: new Headers({
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + (auth.getAccessToken()),
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
            })
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json()
                } else {
                    throw new Error('Error');
                }
            })
            .then(result => {
                if (result !== []) {
                    let candidateArray = Array();
                    Object.keys(result.data).map((k) => {
                        candidateArray.push({ id: result.data[k].id, candidateName: result.data[k].userName, city: result.data[k].city, mobile: result.data[k].mobileNo, userId: result.data[k].userID, eventId: result.data[k].eventID, isConfirmed: result.data[k].isConfirmed  })
                    })
                    // candidateArray = result.data
                    this.setState({
                        canList: candidateArray
                    })
                } else {
                    throw new Error('');
                }
            })
            .catch(error => console.log(''))
    }

    confirmCandidate(id){
        var formBody ={
            "id": id,
            "isConfirmed": true
        }
        fetch(paths.getBaseUrl() + '/api/EventUser/UpdateEventUserIsConfirmed', {
            method: 'PUT',
            body: JSON.stringify(formBody) ,

            headers: new Headers({
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + (auth.getAccessToken()),
                'Content-Type': 'application/json'
            })
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json()
                } else {
                    throw new Error();
                }
            })
            .then(result => {
                if (result.statusCode === 200) {
                    // window.location.reload()
                    this.setState({ _notificationSystem: this.refs.notificationSystem });
                    var _notificationSystem = this.refs.notificationSystem;
                    // var color = Math.floor(Math.random() * 4 + 1);
                    var level = 'success';

                    _notificationSystem.addNotification({
                        title: <span data-notify="icon" className="pe-7s-gift" />,
                        message: (
                            <div>
                                Candidate confirmed successfully.
              </div>
                        ),
                        level: level,
                        position: "tr",
                        autoDismiss: 10
                    });

                    this.setState({
                        canList: [],
                        candidateName: '',
                        username: '',
                        userId: '',
                        contactNo: '',
                        mobileNo: '',
                        emailId: '',
                        password: '',
                        address: '',
                        city: '',
                        state: '',
                        pincode: ''
                    },
                        () => this.getCandidateList())

                } else {
                    this.setState({ _notificationSystem: this.refs.notificationSystem });
                    var _notificationSystem = this.refs.notificationSystem;
                    // var color = Math.floor(Math.random() * 4 + 1);
                    var level = 'error';

                    _notificationSystem.addNotification({
                        title: <span data-notify="icon" className="pe-7s-gift" />,
                        message: (
                            <div>
                                {result.message}
                            </div>
                        ),
                        level: level,
                        position: "tr",
                        autoDismiss: 10
                    });
                    // throw new Error('');
                }

            })
            .catch(error => {
                this.setState({ _notificationSystem: this.refs.notificationSystem });
                var _notificationSystem = this.refs.notificationSystem;
                // var color = Math.floor(Math.random() * 4 + 1);
                var level = 'error';

                _notificationSystem.addNotification({
                    title: <span data-notify="icon" className="pe-7s-gift" />,
                    message: (
                        <div>
                            Something went wrong.
            </div>
                    ),
                    level: level,
                    position: "tr",
                    autoDismiss: 10
                });
                console.log(error)
            })
    
    }

    render() {
        return (
            <div className="content">
                <NotificationSystem ref="notificationSystem" style={style} />
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                // title="Upcoming Events"
                                content={
                                    <div>
                                        <Row>
                                            <Col md={6}>
                                                <small>Select Event:</small>
                                                <Select
                                                    value={this.state.selectedOption}
                                                    onChange={this.handleSelectChange}
                                                    options={this.state.eventList}
                                                />
                                            </Col>
                                        {this.state.selectedOption !== null ? 
                                        
                                        
                                            <Col md={6}>
                                                <br />
                                                Event Name : {this.state.selectedOption['label']}
                                                <br />
                                                    Date : {this.state.selectedOption['fromTime'].split('T')[0]} | {this.state.selectedOption['fromTime'].split('T')[1].split('Z')[0].slice(0, 5) + ' to ' + this.state.selectedOption['toTime'].split('T')[1].split('Z')[0].slice(0, 5)}
                                            </Col>
                                        
                                        : ''}
                                        </Row>
                                        <DataTable
                                            columns={[
                                                {
                                                    name: 'Candidate Name',
                                                    selector: 'candidateName',
                                                    sortable: true,
                                                },
                                                {
                                                    name: 'Mobile No.',
                                                    selector: 'mobile',
                                                    sortable: true,
                                                },
                                                {
                                                    name: 'City',
                                                    selector: 'city',
                                                    sortable: true,
                                                },
                                                {
                                                    name: 'Confirmation',
                                                    sortable: true,
                                                    cell: row => <div>{row.isConfirmed ? "Confirmed" : <Button onClick={this.confirmCandidate.bind(this,row.id)} style={{ fontWeight: "bold", background: 'green', color: '#fff' }}>Confirm</Button>}
                                                    </div>,
                                                }
                                            ]}
                                            data={this.state.canList}
                                            striped
                                            title="Candidate List" />
                                    </div>
                                } />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default UserProfile;
