/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl
} from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { UserCard } from "components/UserCard/UserCard.jsx";
import { Button } from "@material-ui/core";

import avatar from "assets/img/faces/face-3.jpg";
import { TextField } from "@material-ui/core";
import "assets/css/demo.css"
import Flatpickr from "react-flatpickr";
import 'flatpickr/dist/themes/light.css'
// import "flatpickr/dist/themes/";
import DataTable from 'react-data-table-component';
import Select from 'react-select';
import paths from "paths";
import auth from "auth";
import { style } from "variables/Variables.jsx";
import NotificationSystem from "react-notification-system";

const columns = [
  {
    name: 'Name of the Event',
    selector: 'eventName',
    sortable: true,
  },
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
  },
  {
    name: 'Time',
    selector: 'time',
    sortable: true,
  },
  {
    name: 'No. of Candidates',
    selector: 'candidates',
    sortable: true,
  }
];

const data = [
  { id: 1, eventName: 'Birthday Bash', date: '01-12-2020', time: '7PM to 9PM', candidates: 50 },
  { id: 2, eventName: 'Wedding Bash', date: '02-12-2020', time: '7PM to 11PM', candidates: 50 },
  { id: 3, eventName: 'Bachelors Party', date: '03-12-2020', time: '9PM to 11PM', candidates: 50 },
  { id: 4, eventName: 'Investors Meet', date: '04-12-2020', time: '11AM to 2PM', candidates: 50 },
  { id: 5, eventName: 'Birthday Bash', date: '04-12-2020', time: '7PM to 9PM', candidates: 50 },
  { id: 6, eventName: 'Wedding Reception', date: '05-12-2020', time: '6PM to 11PM', candidates: 50 },
  { id: 7, eventName: 'Christmas Eve', date: '25-12-2020', time: '7PM to 10PM', candidates: 50 },

];

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventDate: new Date(),
      eventList: []

    }

    this.handleChange = this.handleChange.bind(this)
    this.onDateChange = this.onDateChange.bind(this)
    this.onFromTimeChange = this.onFromTimeChange.bind(this)
    this.onTillTimeChange = this.onTillTimeChange.bind(this)
    this.handleSelectChange = this.handleSelectChange.bind(this)
    this.getEventList = this.getEventList.bind(this)
    this.sendSMS = this.sendSMS.bind(this)
    this.getDateEvents = this.getDateEvents.bind(this)


  }

  handleChange(event) {
    const target = event.target;
    const value = target.value
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  handleSelectChange = selectedOption => {
    this.setState({ selectedOption });
    console.log(`Option selected:`, selectedOption);

  };

  // onDateChange(e, selectedDate) {
  //   if (selectedDate) {
  //     this.setState({
  //       eventDate: selectedDate
  //     })
  //   }
  // }

  onFromTimeChange(e, selectedDate) {
    if (selectedDate) {
      this.setState({
        eventStartTime: selectedDate
      })
    }
  }

  onTillTimeChange(e, selectedDate) {
    if (selectedDate) {
      this.setState({
        eventEndTime: selectedDate
      })
    }
  }

  componentDidMount() {
    // this.getEventList()
  }

  onDateChange(e, selectedDate) {
    // alert(selectedDate)
    var fromDate = new Date(selectedDate).toISOString()
    var toDate = new Date(selectedDate)
    toDate.setSeconds(toDate.getSeconds() + 86399);
    toDate = new Date(toDate).toISOString()
    if (selectedDate) {
      this.setState({
        eventDate: new Date(selectedDate).toISOString(),
        fromDate,
        toDate
      },
        () => this.getDateEvents())
    }
  }

  getEventList() {
    fetch(paths.getBaseUrl() + '/api/Event/GetAll', {
      headers: new Headers({
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + (auth.getAccessToken()),
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
      })
    })
      .then(response => {
        if (response.status === 200) {
          return response.json()
        } else {
          throw new Error('Error');
        }
      })
      .then(result => {
        if (result !== []) {
          let candidateArray = Array();
          Object.keys(result.data).map((k) => {
            var eDate = result.data[k].fromTime.split('T')[0]
            var timeString = result.data[k].fromTime.split('T')[1].split('Z')[0].slice(0, 5) + ' to ' + result.data[k].toTime.split('T')[1].split('Z')[0].slice(0, 5)
            console.log(result)
            candidateArray.push({ id: result.data[k].id, label: result.data[k].eventName, time: timeString, date: eDate, eventId: result.data[k].id, candidates: result.data[k].totalCandidatesApply })
          })
          // candidateArray = result.data
          this.setState({
            eventList: candidateArray
          })
        } else {
          throw new Error('');
        }
      })
      .catch(error => console.log(''))
  }

  sendSMS(eventId) {
    fetch(paths.getBaseUrl() + '/api/EventUser/SendSmsToEventUserByEventID?eventID=' + eventId, {
      // method: 'POST',
      // body: JSON.stringify(userData),

      headers: new Headers({
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + (auth.getAccessToken()),
        'Content-Type': 'application/json'
      })
    })
      .then(response => {
        if (response.status === 200) {
          return response.json()
        } else {
          throw new Error('');
        }
      })
      .then(result => {
        if (result.statusCode === 200) {
          // window.location.reload()
          this.setState({ _notificationSystem: this.refs.notificationSystem });
          var _notificationSystem = this.refs.notificationSystem;
          // var color = Math.floor(Math.random() * 4 + 1);
          var level = 'success';

          _notificationSystem.addNotification({
            title: <span data-notify="icon" className="pe-7s-gift" />,
            message: (
              <div>
                Message Sent Successfully to all candidates.
              </div>
            ),
            level: level,
            position: "tr",
            autoDismiss: 10
          });
          this.setState({
            eventDate: new Date(),
            eventList: []
          })

          // this.setState({
          //   canList: [],
          //   candidateName: '',
          //   username: '',
          //   userId: '',
          //   contactNo: '',
          //   mobileNo: '',
          //   emailId: '',
          //   password: '',
          //   address: '',
          //   city: '',
          //   state: '',
          //   pincode: ''
          // },
          //   () => this.getCandidateList())

        } else {
          this.setState({ _notificationSystem: this.refs.notificationSystem });
          var _notificationSystem = this.refs.notificationSystem;
          // var color = Math.floor(Math.random() * 4 + 1);
          var level = 'error';

          _notificationSystem.addNotification({
            title: <span data-notify="icon" className="pe-7s-gift" />,
            message: (
              <div>
                {result.message}
              </div>
            ),
            level: level,
            position: "tr",
            autoDismiss: 10
          });
          // throw new Error('');
        }

      })
      .catch(error => {
        this.setState({ _notificationSystem: this.refs.notificationSystem });
        var _notificationSystem = this.refs.notificationSystem;
        // var color = Math.floor(Math.random() * 4 + 1);
        var level = 'error';

        _notificationSystem.addNotification({
          title: <span data-notify="icon" className="pe-7s-gift" />,
          message: (
            <div>
              Something went wrong.
            </div>
          ),
          level: level,
          position: "tr",
          autoDismiss: 10
        });
        console.log(error)
      })
  }

  getDateEvents() {

    fetch(paths.getBaseUrl() + '/api/Event/GetEventsByDate?fromdate=' + this.state.fromDate + '&toDate=' + this.state.toDate, {
      headers: new Headers({
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + (auth.getAccessToken()),
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
      })
    })
      .then(response => {
        if (response.status === 200) {
          return response.json()
        } else {
          throw new Error('Error');
        }
      })
      .then(result => {
        if (result !== []) {
          let candidateArray = Array();
          Object.keys(result.data).map((k) => {
            var eDate = result.data[k].fromTime.split('T')[0]
            var timeString = result.data[k].fromTime.split('T')[1].split('Z')[0].slice(0, 5) + ' to ' + result.data[k].toTime.split('T')[1].split('Z')[0].slice(0, 5)
            console.log(result)
            candidateArray.push({ id: result.data[k].id, label: result.data[k].eventName, time: timeString, date: eDate, eventId: result.data[k].id, candidates: result.data[k].totalCandidatesApply, eventName: result.data[k].eventName })
          })
          // candidateArray = result.data
          this.setState({
            eventList: candidateArray
          })
        } else {
          throw new Error('');
        }
      })
      .catch(error => console.log(''))
  }

  render() {
    return (
      <div className="content">
        <NotificationSystem ref="notificationSystem" style={style} />

        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Message Portal"
                content={
                  <>
                    <Row>
                      <Col md={4}>
                        <small>Select Event Date</small>
                        <Flatpickr
                          value={this.state.eventDate}
                          options={{
                            // minDate: this.state.minDate,
                            // dateFormat: 'd-m-Y',
                            // altInput: true,
                            monthSelectorType: 'static',
                            // altFormat: "d-m-Y",
                            // altInputClass: 'form-control whiteBackground',
                            wrap: true,
                            minDate: new Date().toISOString().split('T')[0]
                          }}
                          onChange={this.onDateChange}>
                          <div className='input-group'>
                            <input className="form-control whiteBackground" type="password" placeholder="Event Date" data-input={true} />
                          </div>
                        </Flatpickr>
                      </Col>
                    </Row>
                    <br />
                    {/* <Row>
                      <Col md={4}>
                        <Select
                          value={this.state.selectedOption}
                          onChange={this.handleSelectChange}
                          options={this.state.eventList}
                        />
                      </Col>
                      <Col md={4}>
                        <Button onClick={this.sendSMS} style={{ fontWeight: "bold", background: '#8c2257eb', color: '#fff', marginTop: '4px' }}>Send SMS</Button>
                      </Col>
                    </Row> */}
                  </>
                }
              />
            </Col>

          </Row>
          <Row>
            <Col md={12}>
              <Card
                // title="All Events"
                content={
                  <DataTable
                    columns={[
                      {
                        name: 'Name of the Event',
                        selector: 'eventName',
                        sortable: true,
                      },
                      {
                        name: 'Date',
                        selector: 'date',
                        sortable: true,
                      },
                      {
                        name: 'Time',
                        selector: 'time',
                        sortable: true,
                      },
                      {
                        name: 'No. of Candidates',
                        selector: 'candidates',
                        sortable: true,
                      },
                      {
                        name: '#',
                        sortable: true,
                        cell: row => <Button onClick={this.sendSMS.bind(this, row.eventId)} style={{ fontWeight: "bold", background: '#8c2257eb', color: '#fff' }}>Send SMS</Button>,
                      }
                    ]
              }
                data={this.state.eventList}
              striped
              title= {this.state.eventList.length === 0 ? 'Events' : 'Events on ' + this.state.eventDate.split('T')[0] } />
            } />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default UserProfile;



